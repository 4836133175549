/*
 * TimeLimit Server Admin UI
 * Copyright (C) 2020 - 2022 Jonas Lochmann
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import { createHeader } from './header'
//import { RadioButton } from './radio'

function getPurchaseIdPlaceholder(): string {
  const now = new Date()

  const day = now.getDate().toString().padStart(2, '0')
  const month = (now.getMonth() + 1).toString().padStart(2, '0')
  const year = now.getFullYear().toString()

  return [year, month, day, 'XYZ'].join('-')
}

export class UnlockPremiumForm2 {
  listener?: UnlockPremiumFormListener = undefined
  readonly root: HTMLElement
  private readonly form: HTMLFormElement
  private readonly purchaseIdField: HTMLInputElement
  private readonly purchaseTokenField: HTMLTextAreaElement
  private readonly submitButton: HTMLInputElement
  private enabled: boolean = true

  constructor () {
    this.form = document.createElement('form')
    this.purchaseIdField = document.createElement('input')
    this.purchaseTokenField = document.createElement('textarea')
    this.submitButton = document.createElement('input')

    this.purchaseIdField.placeholder = getPurchaseIdPlaceholder()
    this.purchaseIdField.value = getPurchaseIdPlaceholder()

    this.purchaseTokenField.rows = 10

    this.submitButton.type = 'submit'
    this.submitButton.value = 'Unlock'

    this.reset()

    this.form.addEventListener('submit', (e) => {
      e.preventDefault()

      const id = this.purchaseIdField.value
      const token = this.purchaseTokenField.value

      if (id.endsWith('XYZ')) return alert('placeholder token used')

      this.listener?.onUnlockPremiumRequested({ token, id })
    })

    this.form.append(
      createHeader('Unlock premium version (new)'),
      this.purchaseIdField,
      this.purchaseTokenField,
      this.submitButton
    )

    this.root = this.form
  }

  reset () {
    this.purchaseIdField.value = getPurchaseIdPlaceholder()
    this.purchaseTokenField.value = ''
    this.isEnabled = true
  }

  set isEnabled (value: boolean) {
    if (value === this.enabled) {
      return
    }

    this.enabled = value

    this.purchaseIdField.disabled = !value
    this.purchaseTokenField.isEnabled = value
    this.submitButton.disabled = !value
  }
}

interface UnlockPremiumFormListener {
  onUnlockPremiumRequested (params: {
    token: string
    id: string
  }): void
}
